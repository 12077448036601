.App {
  @apply h-screen w-full text-white;
}

.link-hover {
  @apply cursor-pointer transition-colors duration-300 hover:text-primary;
}

#root {
  @apply whitespace-pre-line;
}

pre {
  tab-size: 4;
}