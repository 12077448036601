.gradient-text {
  background-image: linear-gradient(
    to right,
    rgba(224, 69, 69, 0.752),
    rgba(204, 55, 85, 0.751),
    rgb(235, 85, 235, 0.751),
    rgba(242, 59, 242, 0.751)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-text-2 {
  background-image: linear-gradient(
    to right,
    rgba(252, 252, 252, 0.752),
    rgba(252, 252, 252, 0.752),
    rgba(252, 252, 252, 0.752),
    rgba(79, 227, 195, 0.751),
    rgba(145, 179, 247, 0.751),
    rgba(221, 104, 221, 0.751),
    rgba(203, 55, 203, 0.751),
    rgba(219, 11, 219, 0.751)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-text-3 {
  background-image: linear-gradient(
    to right,
    rgba(250, 250, 250, 0.752),
    rgba(223, 103, 127, 0.751),
    rgba(247, 145, 247, 0.751),
    rgba(255, 145, 255, 0.751)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

p {
  cursor: default;
}

.animation-transition {
  transition: all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.9s;
}
